<template>
  <v-app>
    <div class="d-flex justify-center flex-column align-center">
      <h1>You're in the panel</h1>
      <v-btn width="100px"
      @click="login()"
      >cpanel</v-btn>
    </div>
  </v-app>
</template>

<script>
export default {
    name:'Home',
    methods:{
      login(){
        this.$router.push('/login')
      }
    }
}
</script>

<style>

</style>