import Vue from 'vue'
import VueRouter from 'vue-router'
import {getAuth} from 'firebase/auth'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    beforeEnter:(to,from,next)=>{
      getAuth().onAuthStateChanged((user)=>{
        if(user){
          return next({
            name:'admin'
          })
        }else next()
      })
    }    
  },
  {
    path: '/panel/admin',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/panel/admin.vue'),
    beforeEnter:(to,from,next)=>{
      getAuth().onAuthStateChanged(user=>{
        if(user) next()
        else{
          return next({
            name:'Login'
          })
        }
      })
    }
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
