import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCu_tL7zt3vOKT8vUAnF6dVn4CUbxK0YKs",
  authDomain: "marketplace-dd993.firebaseapp.com",
  projectId: "marketplace-dd993",
  storageBucket: "marketplace-dd993.appspot.com",
  messagingSenderId: "964120026050",
  appId: "1:964120026050:web:700a7793019ddbce51d45b",
  measurementId: "G-2J7CN79Q7Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default { app }

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
